<template>
  <el-container>
      <el-main style="margin-top: 20px">
        <div style="border-left: 1px solid #ccc;border-right: 1px solid #ccc;height: 100%;width: 1200px;margin:0 auto">
            <div style="border-bottom: 1px solid #ccc;height: 300px">
              <ul id="content">
                <li style="width: 50%">
<!--                  <img src="https://img1.baidu.com/it/u=4001248402,3847064008&fm=253&fmt=auto&app=120&f=JPEG?w=423&h=220" style="padding: 10px;height: 280px;width: 100%"/>-->
                  <img :src="data.bgUrl" style="padding: 10px;height: 280px;width: 100%"/>
                </li>
                <li style="width: 50%">
                  <div style="display: inline-block;height: 70px;width: 100%">
                    <p class="ptag" style="margin-left: 50px;margin-top: 10px">{{data.name}}</p>
                    <div style="font-size: 12px;margin-left: 60px">
                      <span><i class="el-icon-time"></i>{{data.updateTime}}</span>
                      <span style="margin-left: 30px;"><i class="dot"></i>{{data.gameType}}</span>
                      <span style="margin-left: 30px;"><i class="el-icon-coin"></i>{{data.diskSpace}}</span>
                      <span style="margin-left: 30px;"><i class="el-icon-chat-dot-square"></i>{{data.gameLang}}</span>
                    </div>
                  </div>
                  <div>
                    <p class="ptag"  style="font-size: 14px;margin-left: 50px;font-weight: bolder;">
                      <span style="color: #FF9800;"><i class="el-icon-circle-check"></i>加入VIP无限制畅快下载</span>
                      <span v-if="loginname!=''&&limitTime!=''&&unzipcode!=''" style="float: right;color:black;font-size:14px ">激活码/解压码：{{unzipcode}}</span>
                    </p>
                    <div>
                      <ul v-if="loginname!=''&&limitTime!=''" id="downloadUl" style="margin-left: 65px;">
                        <li v-if="newBaiduUrl!==''">
                          百度云盘<span v-if="">(访问密码：{{baiduPwd}})</span>
                          ：<el-button type="success" @click="copyUrl(newBaiduUrl)">点击复制链接</el-button>
                        </li>
                        <li v-if="tianyiUrl!==''">
                          天翼云盘<span v-if="tianyiPanPwd!==''">(访问码：{{tianyiPanPwd}})</span>：<el-button type="success" @click="copyUrl(tianyiUrl)">点击复制链接</el-button>
                        </li>
                        <li v-if="aliyunUrl!==''">
                          阿里云盘：<el-button type="success" @click="copyUrl(aliyunUrl)">点击复制链接</el-button>
                        </li>
                        <li v-if="xunleiUrl!==''">
                          迅雷云盘：<el-button type="success" @click="copyUrl(xunleiUrl)">点击复制链接</el-button>
                        </li>
                        <li v-if="kuakeUrl!==''">
                          夸克云盘：<el-button type="success" @click="copyUrl(kuakeUrl)">点击复制链接</el-button>
                        </li>
                        <li v-if="downloadCode!==''">
                          <el-button type="success" @click="downloadDialogFormVisible = true">不限速下载</el-button>
                        </li>
                      </ul>
                      <div v-if="loginname==''" style="border:1px dashed #ccc;width: 500px;height: 150px;color: gray;text-align: center;margin-left: 50px">
                        <span style="line-height: 150px">登录后可查看下载链接</span>
                      </div>
                      <div v-if="loginname!=''&&limitTime==''" style="border:1px dashed #ccc;width: 500px;height: 150px;color: gray;text-align: center;margin-left: 50px">
                        <span style="line-height: 150px">加入<span style="color: #FF9800">VIP</span>可查看下载链接</span>
                      </div>
                      <div v-if="loginname!=''&&limitTime!=''&&newBaiduUrl==''" style="border:1px dashed #ccc;width: 500px;height: 150px;color: gray;text-align: center;margin-left: 50px">
                        <span style="line-height: 150px">马上上架 敬请期待 ≥▽≤ ！！</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div style="padding: 10px">
              <h3 style="float: left;width: 100%;margin-bottom: 20px">游戏简介：</h3>
              <p class="ptag"  v-html="data.descRemark"></p>
            </div>
        </div>
        <el-dialog title="不限速下载列表（需全部下载）" :visible.sync="downloadDialogFormVisible" width="500px">
          <el-button type="success" @click="allNoLimitDownLoad()">一键下载所有</el-button><span style="color: red;margin-left: 20px">注意点击浏览器提示的允许弹窗</span>
          <ul v-loading="loading" id="nolimitDownloadList" style="height: 200px">
            <li v-for="(data,index) in nolimitList">
              <a @click="download(data.id)" style="font-size: 16px">文件{{index+1}}</a>
            </li>
          </ul>
          <div slot="footer" class="dialog-footer">
            <el-button @click="downloadDialogFormVisible = false">关闭</el-button>
          </div>
        </el-dialog>
      </el-main>
  </el-container>
</template>
<script>

export default {
  data() {
    return {
      loading:false,
      id: 10,
      data:{},
      gameName:'',
      loginname:"",
      downloadDialogFormVisible:false,
      newBaiduUrl:"",
      tianyiUrl:"",
      baiduPwd:"",
      aliyunUrl:"",
      xunleiUrl:"",
      kuakeUrl:"",
      downloadCode:"",
      userId:"",
      limitTime:"",
      unzipcode:"",
      tianyiPanPwd:"",
      nolimitList:[]
    };
  },
  // 卸载
  beforeDestroy() {
  },
  beforeCreate(){
  },
  created() {
    if (sessionStorage.getItem("userName")) {
      this.loginname = sessionStorage.getItem("userName");
    } else {
      this.loginname = "";
    }

    if (sessionStorage.getItem("userId")) {
      this.userId = sessionStorage.getItem("userId");
    } else {
      this.userId = "";
    }
    this.getVipInfos()
  },
  mounted() {
    let id = this.$route.query.gameId;
    this.id = id
    this.loadDetail()
    this.getDownloadInfos();
    this.getNoLimitDownLoadList();
  },
  methods: {
    goHome(){
      window.location.href='/'
    },
    download(fileId){
      this.loading = true;
      this.$axios.get(this.$gameApiurls.getNoLimitDownLoadUrlByCode+"?gameId="+this.id+"&id="+fileId)
        .then((res) => {
          if(res.code==0){
            if(res.data!=null){
              this.downloadByA(res.data,fileId)
            }else{
              this.$message({
                message: '资源出错，请先在网站右上角反馈，马上补好噢！',
                type: 'error'
              });
            }
            this.loading = false;
          }
        });
    },
    downloadByA(url, fileName) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      a.setAttribute('target', '_blank')
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getVipInfos(){
      this.$axios.get(this.$gameApiurls.getUserVipInfo)
        .then((res) => {
          if(res.code==0){
            if(res.data!=null){
              this.limitTime = res.data.limitTime;
            }else{
              this.limitTime = "";
            }
          }
        });
    },
    allNoLimitDownLoad(){
      this.$confirm('确定下载所有文件(会出现多个弹出，请及时点击下载)？', '下载确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get(this.$gameApiurls.getNoLimitDownLoadList+"?id="+this.id)
          .then((res) => {
            if(res.code==0){
              if(res.data!=null){
                for(let i=0;i<res.data.length;i++){
                  this.download(res.data[i].id)
                }
              }
            }
          });
      }).catch(() => {})
    },
    getNoLimitDownLoadList(){
      this.$axios.get(this.$gameApiurls.getNoLimitDownLoadList+"?id="+this.id)
        .then((res) => {
          if(res.code==0){
            if(res.data!=null){
              this.nolimitList = res.data;
            }else{
              this.nolimitList = [];
            }
          }
        });
    },
    getDownloadInfos(){
      if(this.userId!=""){
        this.$axios.get(this.$gameApiurls.getGameDownLoadInfo+"?id="+this.id)
          .then((res) => {
            let data = res.data
            if(data!=null){
              this.newBaiduUrl = data.newBaiduUrl==null?"":data.newBaiduUrl;
              this.baiduPwd = data.baiduPwd==null?"":data.baiduPwd;
              this.tianyiUrl = data.tianyiUrl==null?"":data.tianyiUrl;
              this.aliyunUrl = data.aliyunUrl==null?"":data.aliyunUrl;
              this.xunleiUrl = data.xunleiUrl==null?"":data.xunleiUrl;
              this.kuakeUrl = data.kuakeUrl==null?"":data.kuakeUrl;
              this.unzipcode = data.fileUnzipCode==null?"":data.fileUnzipCode;
              this.tianyiPanPwd = data.tianyiPanPwd==null?"":data.tianyiPanPwd;
              this.downloadCode = data.downloadCode==null?"":data.downloadCode;
            }
          });
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    loadDetail(){
      this.$axios.get(this.$gameApiurls.gameInfo+"?id="+this.id)
        .then((res) => {
          let data = res.data
          this.data = data
        });
    },copyUrl(url){
      // navigator clipboard 需要https等安全上下文
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        this.$message({
          message: '链接已复制到剪贴板',
          type: 'success'
        });
        return navigator.clipboard.writeText(url);
      } else {
        // document.execCommand('copy') 向剪贴板写文本
        let input = document.createElement('input')
        input.style.position = 'fixed'
        input.style.top = '-10000px'
        input.style.zIndex = '-999'
        document.body.appendChild(input)
        input.value = url
        input.focus()
        input.select()
        try {
          let result = document.execCommand('copy')
          document.body.removeChild(input)
          if (!result || result === 'unsuccessful') {
            this.$message({
              message: '复制失败',
              type: 'success'
            });
          } else {
            this.$message({
              message: '链接已复制到剪贴板',
              type: 'success'
            });
          }
        } catch (e) {
          document.body.removeChild(input)
          this.$message({
            message: '当前浏览器不支持复制功能，请检查更新或更换其他浏览器操作',
            type: 'success'
          });
        }
      }
    }
  },
};
</script>

<style scope>
body {
  height: 100vh;
  background-color: #f4fafe;
}
.ptag{
  text-align: left;
  padding: 10px;
}
el-button{
  height: 50px;
}
#content li{
  list-style: none;
  float: left;
}
#nolimitDownloadList>li{
  float: left;
  margin-left: 10px;
  border:1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}
#nolimitDownloadList>li:hover{
  background-color: #10c469;
  cursor: pointer;
}
.dot {
  border-radius: 50%;
  height: 6px;
  margin-right: 5px;
  width: 6px;
  background-color: #fd721f;
  display: inline-block;
}
.el-icon-circle-check{
  color: #FF9800;
  font-weight: bolder;
}
#downloadUl li{
  list-style: none;
  line-height: 35px;
  padding: 10px
}

.input-class .el-input__inner {
  text-align: center;
  border-radius: 30px;
  width: 380px;
  float: right;
  margin-right: 30%;
  margin-top:20px;
}

#headerUl>li{
  float: left;
  list-style: none;
}
  li{
    list-style: none;
  }
  h2{
    font-size: 16px;
  }
.el-icon-coin{
  color: blue;
  font-weight: bolder;
}
.el-icon-chat-dot-square{
  color: green;
  font-weight: bolder;
}
p>img{
  width:800px
}
</style>
